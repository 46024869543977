<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModel">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Reward Points Currency</h1>
                <button class="close_btn left_out" @click="closeModel"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" ref="reward-currency-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Points Currency</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.points_currency }">
                                    <Field autocomplete="off" type="text" name="points_currency" v-model="form.points_currency" placeholder="ex: $" rules="required" />
                                </div>
                                <ErrorMessage name="points_currency" class="text-danger"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModel">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Reward Currency',

        data() {
            return {
                form: {
                    points_currency: ''
                },
            }
        },

        props: {
            modelValue: Boolean,
        },

        components: {
            Field,
            Form,
            ErrorMessage
        },

        computed: mapState({
            reward: state => state.rewardModule.reward,
            loader: state => state.rewardModule.rewardLoader,
        }),

        watch: {
            reward (reward) {
                const vm = this;

                if (reward) {
                    vm.form.points_currency = reward.points_currency;
                }
            },

            modelValue (value) {
                if (value) {
                    const vm = this;

                    if (!Object.keys(vm.reward).length) {
                        vm.getReward();
                    }

                    vm.form.points_currency = vm.reward.points_currency;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        mounted () {
            const vm = this;

            if (vm.reward) {
                vm.form.points_currency = vm.reward.points_currency;
            }
        },

        methods: {
            ...mapActions({
                getReward: 'rewardModule/getReward',
                updatePointCurrency: 'rewardModule/updatePointCurrency',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            handleSubmitForm () {
                const vm = this;

                const currencyForm = vm.$refs['reward-currency-form'];

                currencyForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleSubmit(currencyForm);
                    }
                })
            },

            handleSubmit (form) {
                const vm = this;

                vm.form.redeemed_notification = vm.reward.redeemed_notification;
                vm.form.awarded_notification  = vm.reward.awarded_notification;
                vm.form.points_name           = vm.reward.points_name;
                vm.form.awarded_email         = vm.reward.awarded_email;
                vm.form.awarded_sms           = vm.reward.awarded_sms;
                vm.form.redeemed_email        = vm.reward.redeemed_email;
                vm.form.redeemed_sms          = vm.reward.redeemed_sms;
                vm.form.awarded_subject       = vm.reward.awarded_subject;
                vm.form.redeemed_subject      = vm.reward.redeemed_subject;
                vm.form.awarded_status        = vm.reward.awarded_status;
                vm.form.redeemed_status       = vm.reward.redeemed_status;
                vm.form.setFieldError         = form.setFieldError;
                vm.form.closeModel            = vm.closeModel;

                vm.updatePointCurrency(vm.form);
            }
        }
    }
</script>
